<!-- 
    页面重构：需求设计变更了
    1.从其他页面跳过来时，需要获取参数
    2.搜索框要与HeaderNav的搜索框内容同步
 -->
<template>
    <div class='container' ref="whole-container">
        <HeaderNav ref="headerNav" />
        <div class="content">
            <div class="inner">
                <!-- 搜索块 -->
                <div class="searchBlock">
                    <!-- 搜索框 -->
                    <div class="searchBox">
                        <input type="text" v-model="filterObj.keyword" class="searchBoxInput" placeholder="搜索名称/关键词"
                            maxlength="50" @keyup.enter="onlySearchKeyWord">
                        <div class="searchBoxBtn" @click="onlySearchKeyWord"></div>
                    </div>
                    <!-- 搜索条件 -->
                    <div class="filterBox">
                        <div class="filterRow" v-for="row in filterOpt" :key="row.rowName">
                            <!-- 地区 -->
                            <div class="filterRowInfo" v-if="row.rowName=='area'">
                                <div class="filterLabel">{{row.rowLabel}}</div>
                                <!-- 截取的展示部分 -->
                                <div class="filterSubItems" v-if="!row.showAll">
                                    <div class="filterItem">
                                        <input type="checkbox" v-model="row.selectAll" @change="selectArea(true)" />
                                        <div>全国</div>
                                    </div>
                                    <div class="filterItem" v-for="item in row.dicDataSub" :key="item.areaName">
                                        <input type="checkbox" v-model="item.selectFlag"
                                            @change="selectArea(false,'sub',item)" />
                                        <div>{{item.areaName}}</div>
                                    </div>
                                </div>
                                <!-- 全部的内容 -->
                                <div class="filterAllItems" v-if="row.showAll">
                                    <div class="itemsBox" v-for="items in row.dicData" :key="items.areaName">
                                        <div class="itemsLabel">{{items.areaName}}</div>
                                        <div class="childBox" v-for="child in items.children" :key="child.areaName">
                                            <input type="checkbox" v-model="child.selectFlag"
                                                @change="selectArea(false,'all',child)" />
                                            <div>{{child.areaName}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="filterMore" @click="toggleShowAll(row)" v-if="!row.showAll">
                                    更多 <img class="searchIcon_showAll" src="@/assets/img/searchShowall.png" alt="">
                                </div>
                                <div class="filterMore" @click="toggleShowAll(row)" v-if="row.showAll">
                                    收起 <img class="searchIcon_rollUp" src="@/assets/img/searchRollup.png" alt="">
                                </div>
                                <div class="filterMore top36" @click="clearSelectArea(row)" v-if="row.showAll">
                                    清空 <img class="searchIcon_clear" src="@/assets/img/searchClear.png" alt="">
                                </div>
                            </div>
                            <!-- 行业 -->
                            <div class="filterRowInfo" v-if="row.rowName=='industry'">
                                <div class="filterLabel">{{row.rowLabel}}</div>
                                <!-- 截取的展示部分 -->
                                <div class="filterSubItems" v-if="!row.showAll">
                                    <div :class="{filterItemUn:true,filterItemEn:row.selectAll}"
                                        @click="changeSelectItem(row.rowName,true)">
                                        <div>全部</div>
                                    </div>
                                    <div :class="{filterItemUn:true,filterItemEn:item.selectFlag}"
                                        v-for="item in row.dicDataSub" :key="item.id"
                                        @click="changeSelectItem(row.rowName,false,item)">
                                        <div>{{item.name}}</div>
                                    </div>
                                </div>
                                <!-- 全部的内容 -->
                                <div class="filterAllItems" v-if="row.showAll">
                                    <div :class="{filterItemUn:true,filterItemEn:item.selectFlag}"
                                        v-for="item in row.dicData" :key="item.id"
                                        @click="changeSelectItem(row.rowName,false,item)">
                                        <div>{{item.name}}</div>
                                    </div>
                                </div>
                                <div class="filterMore" @click="toggleShowAll(row)" v-if="!row.showAll">
                                    更多 <img class="searchIcon_showAll" src="@/assets/img/searchShowall.png" alt="">
                                </div>
                                <div class="filterMore" @click="toggleShowAll(row)" v-if="row.showAll">
                                    收起 <img class="searchIcon_rollUp" src="@/assets/img/searchRollup.png" alt="">
                                </div>
                            </div>
                            <!-- 栏目 -->
                            <div class="filterRowInfo" v-if="row.rowName=='column'">
                                <div class="filterLabel">{{row.rowLabel}}</div>
                                <div class="filterSubItems" v-if="!row.showAll">
                                    <div :class="{filterItemUn:true,filterItemEn:row.selectAll}"
                                        @click="changeSelectItem(row.rowName,true)">
                                        <div>全部</div>
                                    </div>
                                    <div :class="{filterItemUn:true,filterItemEn:item.selectFlag}"
                                        v-for="item in row.dicData" :key="item.id"
                                        @click="changeSelectItem(row.rowName,false,item)">
                                        <div>{{item.name}}</div>
                                    </div>
                                </div>
                            </div>
                            <!-- 时间 -->
                            <div class="filterRowInfo" v-if="row.rowName=='time'">
                                <div class="filterLabel">{{row.rowLabel}}</div>
                                <div class="filterSubItems" v-if="!row.showAll">
                                    <div :class="{filterItemUn:true,filterItemEn:item.selectFlag,specItemEn:true}"
                                        v-for="item in row.dicData" :key="item.id"
                                        @click="changeSelectItem(row.rowName,false,item)">
                                        <div>{{item.name}}</div>
                                    </div>
                                    <div class="diyTimeBox" v-if="row.diyTime">
                                        <el-date-picker v-model="filterObj.createDate" class="timePicker" type="date"
                                            placeholder="选择日期" value-format="yyyy-MM-dd">
                                        </el-date-picker>
                                        <div class="timeMargin"></div>
                                        <el-date-picker v-model="filterObj.endDate" class="timePicker" type="date"
                                            placeholder="选择日期" value-format="yyyy-MM-dd">
                                        </el-date-picker>
                                        <div class="searchFilter yellowBtn" @click="loadData">
                                            <img src="@/assets/img/search_icon_white.png" alt="">
                                            <span>搜索</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 类型 -->
                            <div class="filterRowInfo" v-if="row.rowName=='type'">
                                <div class="filterLabel">{{row.rowLabel}}</div>
                                <div class="filterSubItems" v-if="!row.showAll">
                                    <div :class="{filterItemUn:true,filterItemEn:row.selectAll}"
                                        @click="changeSelectItem(row.rowName,true)">
                                        <div>全部</div>
                                    </div>
                                    <div :class="{filterItemUn:true,filterItemEn:item.selectFlag}"
                                        v-for="item in row.dicData" :key="item.id"
                                        @click="changeSelectItem(row.rowName,false,item)">
                                        <div>{{item.name}}</div>
                                    </div>
                                </div>
                                <div class="handleBox">
                                    <div class="smallSearch">
                                        <input type="text" maxlength="50" v-model="filterObj.keyword"
                                            @keyup.enter="loadData" />
                                        <div class="smallBtn" @click="loadData(true)">
                                            <img src="@/assets/img/search_icon_white.png" alt="">
                                            <span>搜索</span>
                                        </div>
                                        <div class="resetBtn" @click="clearSearch">
                                            重置
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 数据块 -->
                <div class="dataBlock">
                    <div class="dataList">
                        <img src="@/assets/img/pc_searchNull.png" alt="" class="searchNull"
                            v-if="total==0&&listData.length==0">
                        <!-- <img src="@/assets/img/pc_searching.png" alt="" class="searching"
                            v-else-if="total==1&&listData.length==0"> -->
                        <div class="dataItem" v-for="item in listData" :key="item.id" @click="goDetail(item)"
                            :class="{'inLoading':inLoading,'unLoading':!inLoading}">
                            <div class="topicInfo">
                                <div class="tips">
                                    <div class="tip areaTip">{{item.regionName}}</div>
                                    <div class="tip columnTip">{{item.managementName}}</div>
                                    <div class="tip industryTip"
                                        v-for="(item,index) in (item.classificationName ? item.classificationName.split(',').slice(0,3) : [])"
                                        :key="index + item">{{item}}</div>
                                    <div class="tip industryTip"
                                        v-if="item.classificationName&&item.classificationName.split(',').length > 3">
                                        更多>></div>
                                </div>
                                <div class="topicTit" :style="item.browsingType==1?'color:#678dc8':''"
                                    v-html="item.title">
                                </div>
                            </div>
                            <div class="topicTime">{{item.createDate}}</div>
                        </div>
                    </div>
                    <div class="pageNintionBox">
                        <el-pagination align="center" background layout="prev, pager, next"
                            :current-page.sync="filterObj.pageNum" :page-size="filterObj.pageSize" :page-count="5"
                            :total="total" @current-change="handleSizeChange" hide-on-single-page
                            :class="{'myPager':true,'hideLastPage':hideLastPage}">
                            <!-- , slot,jumper -->
                            <!-- <div class="pageNumTip">
                                <span class="pageNumNow">{{filterObj.pageNum}}</span>
                                <span>/</span>
                                <span>{{maxPage}}</span>
                            </div> -->
                        </el-pagination>
                        <!-- <div class="pageNumConfirm" v-if="maxPage>1&&listData.length>0">确定</div> -->
                    </div>
                </div>
            </div>
        </div>

        <FooterNav />
        <SlideFixNav />
    </div>
</template>
<script>
    import HeaderNav from "@/components/HeaderNav"
    import FooterNav from "@/components/FooterNav"
    import SlideFixNav from "@/components/SlideFixNav"
    import {
        getHomepage,
    } from '@/request/homepage';
    import {
        getHotKey,
        searchTopic,
        searchDelete
    } from "@/request/search";
    import {
        viewCount
    } from "@/request/viewCount"
    import {
        columnManagement,
        getFixColumn
    } from "@/request/column"
    import {
        getIndustry,
        getFixIndustry
    } from "@/request/industry"
    import {
        getTopic,
        getTopicVip,
        collectTopic,
        channelSave
    } from "@/request/topic"
    import areas from "@/utils/area"
    import filterAreaList from "@/utils/filterAreaList"
    export default {
        name: 'Search',
        components: {
            HeaderNav,
            FooterNav,
            SlideFixNav
        },
        data() {
            return {
                filterOpt: [{
                        rowName: "area",
                        rowLabel: "所在地区：",
                        selectAll: true,
                        showAll: false,
                        dicDataSub: [], //截取的展示的选项
                        dicData: [], //全部选项
                    },
                    {
                        rowName: "industry",
                        rowLabel: "行业分类：",
                        selectAll: true,
                        showAll: false,
                        dicDataSub: [], //截取的展示的选项
                        dicData: [], //全部选项
                    },
                    {
                        rowName: "column",
                        rowLabel: "栏目：",
                        selectAll: true,
                        dicData: [], //全部选项
                    },
                    {
                        rowName: "time",
                        rowLabel: "发布时间：",
                        diyTime: false,
                        dicData: [{ //全部选项
                            name: "最近一个月",
                            id: 0,
                            selectFlag: false
                        }, {
                            name: "最近三个月",
                            id: 1,
                            selectFlag: true
                        }, {
                            name: "最近一年",
                            id: 2,
                            selectFlag: false
                        }, {
                            name: "最近三年",
                            id: 3,
                            selectFlag: false
                        }, {
                            name: "自定义",
                            id: 4,
                            selectFlag: false
                        }],
                    },
                    {
                        rowName: "type",
                        rowLabel: "搜索类型：",
                        selectAll: true,
                        dicData: [{ //全部选项
                            name: "标题",
                            id: 1,
                            selectFlag: false
                        }, {
                            name: "内容",
                            id: 2,
                            selectFlag: false
                        }],
                    }
                ],
                filterObj: {
                    pageNum: 1,
                    pageSize: 30,
                    keyword: "",
                    createDate: "",
                    endDate: ""
                },
                maxPage: 1,
                total: 1,
                listData: [],
                hideLastPage: false, //后台无法指定跳转大页数，所以前端加判断
                isLoading: true, //element-ui
                inLoading: true, //自定义
            };
        },
        created() {

        },
        mounted() {
            this.getQuery()
            this.initFilter()

            let param = this.$route.query
            //默认搜索
            this.filterObj.createDate = this.getDateFor(90);
            this.filterObj.endDate = this.getDateFor(-1);
            // if(param.pageNum){
            //   this.filterObj.pageNum = Number(param.pageNum)
            //   this.loadData(true)
            //   return
            // }
            this.loadData()
        },
        watch: {
            "$route.query"(val) {
                this.getQuery()
                this.filterObj.pageNum = Number(this.$route.query.pageNum) || 1
                this.filterObj.currentPage = Number(this.$route.query.pageNum) || 1
                console.log(this.filterObj.currentPage)
                this.loadData()
            },
            "filterObj.keyword"(val) {
                this.$refs.headerNav.keyword = val;
            }
        },
        methods: {
            handleSizeChange(val) {
                this.$refs['whole-container'].style.opacity = 0
                let param = this.getParam()
                this.$router.push({
                    path: '/search',
                    query: {
                        ...param,
                        pageNum: val
                    }
                })

                window.scroll(0, 0)
                this.loadData()

            },
            /**筛选相关 */
            initFilter() { //初始化筛选
                // 地区初始化
                const rowArea = this.findObjInArr(this.filterOpt, "rowName", "area");
                let areaList = [...filterAreaList];
                areaList.forEach(area => {
                    area.children.forEach(subArea => {
                        subArea.selectFlag = false;
                    })
                })
                rowArea.dicData = [...areaList];
                // areaList.forEach(area => {
                //     area.children.forEach(subArea => {
                //         if (rowArea.dicDataSub.length < 9) {
                //             rowArea.dicDataSub.push({
                //                 ...subArea,
                //             })
                //         }
                //     })
                // })
                rowArea.dicDataSub = [{
                    areaName: "北京",
                    selectFlag: false
                }, {
                    areaName: "上海",
                    selectFlag: false
                }, {
                    areaName: "天津",
                    selectFlag: false
                }, {
                    areaName: "重庆",
                    selectFlag: false
                }, {
                    areaName: "河北",
                    selectFlag: false
                }, {
                    areaName: "山西",
                    selectFlag: false
                }, {
                    areaName: "辽宁",
                    selectFlag: false
                }, {
                    areaName: "吉林",
                    selectFlag: false
                }, {
                    areaName: "江苏",
                    selectFlag: false
                }, ]
                // 行业初始化
                const rowIndustry = this.findObjInArr(this.filterOpt, "rowName", "industry");
                getFixIndustry().then(res => {
                    res.data.forEach(industry => {
                        if (rowIndustry.dicDataSub.length < 11) {
                            rowIndustry.dicDataSub.push({
                                ...industry,
                                selectFlag: false
                            });
                        }
                        rowIndustry.dicData.push({
                            ...industry,
                            selectFlag: false
                        });
                    })
                })
                // 栏目初始化
                const rowColumn = this.findObjInArr(this.filterOpt, "rowName", "column");
                getFixColumn().then(res => {
                    res.data.forEach(item => {
                        rowColumn.dicData.push({
                            ...item,
                            selectFlag: false
                        })
                    })

                })
            },
            //筛选-变化
            //地区
            toggleShowAll(row) { //展示地区，隐藏地区
                row.showAll = !row.showAll;
            },
            selectArea(selectAll, type, area) { //选中地区时
                const rowArea = this.findObjInArr(this.filterOpt, "rowName", "area");
                if (selectAll) { //全选操作
                    this.clearSelectArea();
                } else {
                    //sub类型中选中的，在all中也要选中
                    rowArea.selectAll = false;
                    if (type == "sub") {
                        rowArea.dicData.forEach(fArea => {
                            fArea.children.forEach(subArea => {
                                if (subArea.areaName == area.areaName) {
                                    subArea.selectFlag = area
                                        .selectFlag; //这里当截取部分的搜索改变时，若变为false的话，all中也要变为false，true同理
                                }
                            })
                        })
                    } else {
                        //all中选中的，在sub类型中也要选中
                        let newSubAreaList = [];
                        rowArea.dicData.forEach(fArea => {
                            fArea.children.forEach(subArea => {
                                if (subArea.selectFlag && newSubAreaList.length < 9) {
                                    newSubAreaList.push({
                                        ...subArea
                                    })
                                }
                            })
                        })
                        //如果不够9个，要凑齐9个
                        if (newSubAreaList.length < 9) {
                            rowArea.dicData.forEach(fArea => {
                                fArea.children.forEach(subArea => {
                                    if (!subArea.selectFlag && newSubAreaList.length < 9) {
                                        newSubAreaList.push(subArea);
                                    }
                                })
                            })
                        }
                        rowArea.dicDataSub = newSubAreaList;
                    }
                }
                this.filterObj.pageNum = 1;
                this.loadData();
            },
            //行业、栏目、发布时间、搜索类型
            changeSelectItem(rowName, selectAll, item) {
                if (rowName == "industry") {
                    const rowIndustry = this.findObjInArr(this.filterOpt, "rowName", "industry");
                    this.clearSelectIndustry();
                    if (selectAll) {
                        rowIndustry.selectAll = true;
                    } else {
                        rowIndustry.selectAll = false;
                        rowIndustry.dicDataSub.forEach(dic => {
                            if (dic.id == item.id) {
                                dic.selectFlag = true;
                            }
                        })
                        rowIndustry.dicData.forEach(dic => {
                            if (dic.id == item.id) {
                                dic.selectFlag = true;
                            }
                        })
                    }
                } else if (rowName == "column") {
                    const rowColumn = this.findObjInArr(this.filterOpt, "rowName", "column");
                    this.clearSelectColumn();
                    if (selectAll) {
                        rowColumn.selectAll = true;
                    } else {
                        item.selectFlag = true;
                        rowColumn.selectAll = false;
                    }
                } else if (rowName == "time") {
                    const rowTime = this.findObjInArr(this.filterOpt, "rowName", "time");
                    this.clearSelectTime();
                    item.selectFlag = true;
                    if (item.id == 4) {
                        rowTime.diyTime = true;
                    } else {
                        rowTime.diyTime = false;
                        this.filterObj.endDate = this.getDateFor(-1);
                        if (item.id == 0) {
                            this.filterObj.createDate = this.getDateFor(30);
                        } else if (item.id == 1) {
                            this.filterObj.createDate = this.getDateFor(90);
                        } else if (item.id == 2) {
                            this.filterObj.createDate = this.getDateFor(365);
                        } else if (item.id == 3) {
                            this.filterObj.createDate = this.getDateFor(365 * 3);
                        }
                    }
                } else if (rowName == "type") {
                    const rowType = this.findObjInArr(this.filterOpt, "rowName", "type");
                    this.clearSelectType();
                    if (selectAll) {
                        rowType.selectAll = true;
                    } else {
                        item.selectFlag = true;
                        rowType.selectAll = false;
                    }
                }
                this.filterObj.pageNum = 1;
                this.loadData();
            },
            //筛选-清空
            clearSearch() { //全部清空
                this.clearSelectIndustry();
                this.clearSelectColumn();
                this.clearSelectTime();
                this.clearSelectType();
                this.filterObj = {
                    pageNum: 1,
                    pageSize: 30,
                    keyword: "",
                    createDate: "",
                    endDate: "",
                    time: undefined,
                }
                this.loadData()
            },
            clearSelectArea() { //地区清空
                const rowArea = this.findObjInArr(this.filterOpt, "rowName", "area");
                rowArea.selectAll = true;
                rowArea.dicDataSub.forEach(area => {
                    area.selectFlag = false;
                })
                rowArea.dicData.forEach(area => {
                    area.children.forEach(subArea => {
                        subArea.selectFlag = false;
                    })
                })
                rowArea.dicDataSub = [{
                    areaName: "北京",
                    selectFlag: false
                }, {
                    areaName: "上海",
                    selectFlag: false
                }, {
                    areaName: "天津",
                    selectFlag: false
                }, {
                    areaName: "重庆",
                    selectFlag: false
                }, {
                    areaName: "河北",
                    selectFlag: false
                }, {
                    areaName: "山西",
                    selectFlag: false
                }, {
                    areaName: "辽宁",
                    selectFlag: false
                }, {
                    areaName: "吉林",
                    selectFlag: false
                }, {
                    areaName: "江苏",
                    selectFlag: false
                }, ]
            },
            clearSelectIndustry() { //行业清空
                const rowIndustry = this.findObjInArr(this.filterOpt, "rowName", "industry");
                rowIndustry.dicDataSub.forEach(dic => {
                    dic.selectFlag = false;
                })
                rowIndustry.dicData.forEach(dic => {
                    dic.selectFlag = false;
                })
                rowIndustry.selectAll = true;
            },
            clearSelectColumn() { //栏目清空
                const rowColumn = this.findObjInArr(this.filterOpt, "rowName", "column");
                rowColumn.dicData.forEach(dic => {
                    dic.selectFlag = false;
                })
                rowColumn.selectAll = true;
            },
            clearSelectTime() { //时间清空
                const rowTime = this.findObjInArr(this.filterOpt, "rowName", "time");
                rowTime.dicData.forEach(dic => {
                    dic.selectFlag = false;
                })
            },
            clearSelectType() { //类型清空
                const rowType = this.findObjInArr(this.filterOpt, "rowName", "type");
                rowType.dicData.forEach(dic => {
                    dic.selectFlag = false;
                })
                rowType.selectAll = true;
            },
            /**列表数据加载 */
            loadData(noResetPage) { //列表数据
                this.inLoading = true;
                if (noResetPage === true) {
                    this.filterObj.pageNum = 1;
                }
                let param = this.getParam();
                // param.currentPage = 333;
                searchTopic(param).then(res => {
                    this.$refs['whole-container'].style.opacity = 1
                    this.maxPage = Number(res.data.pages);
                    if (this.maxPage < 40) {
                        this.hideLastPage = false;
                    } else {
                        this.hideLastPage = true;
                    }
                    this.total = Number(res.data.total);
                    this.listData = res.data.records.map(item => {
                        item.createDate = item.createDate.substring(0, 11);
                        item.regionName = item.regionName.substring(0, 3);
                        return item
                    });
                    this.isLoading = false;
                    this.inLoading = false;
                })
            },
            getParam() { //请求列表参数
                //发布时间
                const param = {
                    ...this.filterObj
                };
                //补丁
                param.currentPage = param.pageNum;
                //地区
                const rowArea = this.findObjInArr(this.filterOpt, "rowName", "area");
                if (!rowArea.selectAll) {
                    let choosedArea = [];
                    rowArea.dicData.forEach(area => {
                        area.children.forEach(subArea => {
                            if (subArea.selectFlag) {
                                choosedArea.push(subArea.areaName);
                            }
                        })
                    })
                    param.regionName = choosedArea.join(",");
                }
                //行业
                const rowIndustry = this.findObjInArr(this.filterOpt, "rowName", "industry");
                if (!rowIndustry.selectAll) {
                    rowIndustry.dicData.forEach(dict => {
                        if (dict.selectFlag) {
                            param.classificationName = dict.name;
                            param.classificationId = dict.id;
                        }
                    })
                }
                //栏目
                const rowColumn = this.findObjInArr(this.filterOpt, "rowName", "column");
                if (!rowColumn.selectAll) {
                    rowColumn.dicData.forEach(dict => {
                        if (dict.selectFlag) {
                            param.managementName = dict.name;
                            param.managementId = dict.id;
                        }
                    })
                }
                //搜索类型
                const rowType = this.findObjInArr(this.filterOpt, "rowName", "type");
                if (!rowType.selectAll) {
                    rowType.dicData.forEach(dict => {
                        if (dict.selectFlag) {
                            param.searchType = dict.id;
                        }
                    })
                } else {
                    param.searchType = 0;
                }
                //结束日期 多往后走一天
                // param.endDate = this.getDateFor(-1, param.endDate)
                return param
            },
            onlySearchKeyWord() { //仅搜索关键字
                this.clearSelectArea();
                this.clearSelectIndustry();
                this.clearSelectColumn();
                this.clearSelectTime();
                //重置发布时间筛选为最近三个月
                let timeF = this.findObjInArr(this.filterOpt, "rowName", "time");
                timeF.dicData[1].selectFlag = true;
                //--
                this.clearSelectType();
                this.filterObj = {
                    pageNum: 1,
                    pageSize: 30,
                    keyword: this.filterObj.keyword,
                    createDate: "",
                    endDate: this.getDateFor(-1)
                }
                this.loadData();
            },
            /**其他 */
            goDetail(item) {
                item.browsingType = 1;
                let routeUrl = this.$router.resolve({
                    name: "Topic",
                    query: {
                        id: item.uuid
                    }
                })
                window.open(routeUrl.href, '_blank');
            },
            /** 工具方法 **/
            getQuery() { //router传参
                let query = this.$route.query;
                if (query.keyword) {
                    this.$set(this.filterObj, "keyword", query.keyword);
                    this.$refs.headerNav.keyword = query.keyword;
                }
            },
            findObjInArr(arr, key, val) { //在数组中找到符合条件的对象引用
                try {
                    return arr.filter(item => {
                        if (item[key] === val) {
                            return item
                        }
                    })[0];
                } catch (err) {
                    console.log(err)
                }
            },
            getDateFor(day, startDay) { //获取倒数day天的日期(day)
                let today = new Date();
                if (startDay) {
                    today = new Date(startDay);
                }
                let beforMilliseconds = today.getTime() - 1000 * 3600 * 24 * day;
                let beforday = new Date();
                beforday.setTime(beforMilliseconds);
                let strYear = beforday.getFullYear();
                let strDay = beforday.getDate();
                let strMonth = beforday.getMonth() + 1;
                if (strMonth < 10) {
                    strMonth = "0" + strMonth;
                }
                if (strDay < 10) {
                    strDay = "0" + strDay;
                }
                return strYear + "-" + strMonth + "-" + strDay;
            },
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo
            }
        },

    };
</script>
<style scoped lang="scss">
    .content {
        width: 100%;
        background-image: url("../assets/img/indexBanner.png");
        background-repeat: no-repeat;
        background-size: contain;
        padding-top: 16px;
        padding-bottom: 44px;

        .inner {
            width: 1200px;
            min-height: 1000px;
            margin: 0 auto;
        }
    }

    .searchIcon_rollUp {
        width: 10px;
        height: 8px;
    }

    .searchIcon_clear {
        width: 12px;
        height: 10px;
    }

    .searchIcon_showAll {
        width: 10px;
        height: 10px;
    }


    .searchBlock {
        box-sizing: border-box;
        min-height: 278px;
        background: #ffffff;
        border-radius: 16px;
        box-shadow: 6px 10px 17px 0px rgba(1, 69, 133, 0.21);
        padding-top: 20px;
        padding-bottom: 4px;
        margin-bottom: 10px;


        .searchBox {
            width: 902px;
            height: 40px;
            margin: auto;
            border: 2px solid #4385f5;
            overflow: hidden;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;

            .searchBoxInput {
                display: block;
                width: 824px;
                height: 40px;
                text-indent: 24px;
                font-size: 16px;
                border: none;
                box-sizing: border-box;
            }

            .searchBoxBtn {
                width: 78px;
                height: 40px;
                background: #4385f5;
                background-image: url("../assets/img/search_icon_white.png");
                background-size: 26px 26px;
                background-position: center center;
                background-repeat: no-repeat;
                cursor: pointer;
            }

        }

        .filterBox {
            width: 1128px;
            margin: 0 auto;

            .filterRow {
                min-height: 26px;
                margin-bottom: 16px;

                .filterRowInfo {
                    display: flex;
                    position: relative;


                    .filterLabel {
                        width: 80px;
                        box-sizing: border-box;
                        padding-right: 8px;
                        margin-right: 20px;
                        font-size: 14px;
                        text-align: right;
                        color: #666666;
                        border-right: 1px solid #f1efef;
                    }

                    // 截取的展示部分
                    .filterSubItems {
                        display: flex;
                        height: 26px;
                        font-size: 14px;

                        // 地区
                        .filterItem {
                            display: flex;
                            align-items: center;
                            color: #333333;
                            line-height: 20px;
                            margin-right: 40px;

                            input {
                                margin: 0;
                                color: #3d7fff;
                                background-color: #3d7fff;
                                margin-right: 3px;
                            }
                        }

                    }

                    // 展开全部按钮
                    .filterMore {
                        position: absolute;
                        right: 0;
                        width: 63px;
                        height: 22px;
                        background: #4385f5;
                        border-radius: 13px;
                        color: white;
                        text-align: center;
                        line-height: 22px;
                        cursor: pointer;
                    }

                    .filterMore.top36 {
                        top: 40px;
                    }

                    .filterAllItems {
                        width: 964px;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        font-size: 14px;

                        .itemsBox {
                            display: flex;
                            align-items: center;
                            margin-right: 38px;
                            margin-bottom: 12px;

                            &:first-child {
                                margin-right: 26px;
                            }

                            &:last-child {
                                margin-right: 0;
                            }

                            .itemsLabel {
                                font-size: 14px;
                                font-weight: 600;
                                text-align: right;
                                color: #242424;
                                line-height: 20px;
                                margin-right: 12px;
                            }

                            .childBox {
                                display: flex;
                                align-items: center;
                                margin-right: 15px;
                            }
                        }

                    }

                    .filterItemUn {
                        height: 22px;
                        padding: 3px 0px;
                        margin-bottom: 4px;
                        margin-right: 20px;
                        cursor: pointer;
                    }

                    .filterItemEn {
                        background: #4385f5;
                        border-radius: 13px;
                        color: white;
                        padding: 3px 11px;
                        cursor: pointer;
                    }

                    .filterItemEn.specItemEn {
                        padding: 3px 24px;
                    }

                    //时间
                    .diyTimeBox {
                        margin-left: 10px;
                        display: flex;

                        .timePicker {
                            width: 119px;
                            height: 26px;
                            overflow: hidden;
                            display: block;
                            box-sizing: border-box;
                            border: 1px solid #e8e8e8;
                            border-radius: 1px;

                            input {
                                height: 26px !important;
                                line-height: 26px !important;
                            }
                        }

                        .timeMargin {
                            width: 16px;
                            height: 1px;
                            background-color: #979797;
                            margin: 12px 8px;
                        }

                        .searchFilter {
                            margin: 0;
                            margin-left: 10px;
                        }

                        .yellowBtn {
                            height: 26px;
                            border-radius: 13px;
                            line-height: 26px;
                            text-align: center;
                            color: white;
                            margin-right: 8px;
                            margin-bottom: 8px;
                            cursor: pointer;
                            width: 90px;
                            background-color: #FF9200;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                width: 18px;
                                height: 16px;
                                margin-right: 4px;
                            }
                        }
                    }

                    //类型

                    .smallSearch {
                        display: flex;

                        input {
                            border-top-left-radius: 13px;
                            border-bottom-left-radius: 13px;
                            border: 1px solid #e8e8e8;
                            display: block;
                            width: 129px;
                            height: 24px;
                            text-indent: 20px;
                        }

                        .smallBtn {
                            font-size: 14px;
                            height: 28px;
                            border-top-right-radius: 13px;
                            border-bottom-right-radius: 13px;
                            line-height: 28px;
                            text-align: center;
                            color: white;
                            margin-right: 10px;
                            cursor: pointer;
                            width: 70px;
                            background-color: #FF9200;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                width: 18px;
                                height: 16px;
                                margin-right: 4px;
                            }
                        }

                        .resetBtn {
                            width: 50px;
                            height: 28px;
                            opacity: 1;
                            border: 1px solid #cacaca;
                            border-radius: 14px;
                            font-size: 14px;
                            font-weight: 400;
                            text-align: center;
                            color: #666666;
                            line-height: 28px;
                            cursor: pointer;
                        }
                    }
                }

            }
        }
    }

    .dataBlock {
        width: 1200px;
        margin: 0 auto;
        overflow: hidden;

        .dataList {
            min-height: 90px;
            background-color: #fff;
            margin-bottom: 72px;
            border-radius: 16px;
            overflow: hidden;

            .searchNull {
                display: block;
                margin: 0 auto;
                width: 272px;
                height: 260px;
                padding: 50px 0 60px 0;
            }

            .searching {
                display: block;
                margin: 0 auto;
                width: 272px;
                height: 200px;
                padding: 50px 0 60px 0;
            }

            .dataItem {
                width: 100%;
                padding: 30px 22px;
                overflow: hidden;
                box-sizing: border-box;
                border-bottom: 1px solid #f5f5f5;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;
                transition: all 500ms;

                &:last-child {
                    border-bottom: none;
                    border-bottom-right-radius: 16px;
                    border-bottom-left-radius: 16px;
                }

                &:hover {
                    box-shadow: 6px 10px 17px 0px rgba(1, 69, 133, 0.21), 0px 3px 5px 0px rgba(0, 0, 0, 0.15);

                    .topicTit {
                        color: #266ddc;
                    }

                    .topicTime {
                        color: #266ddc;
                    }
                }


                .topicInfo {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .topicTit {
                        width: 800px;
                        font-weight: 400;
                        text-align: left;
                        color: #333333;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        font-size: 16px;
                    }


                    .tips {
                        display: flex;
                        align-items: center;

                        .tip {
                            // width: 80px;
                            height: 28px;
                            box-sizing: border-box;
                            padding: 0 20px;
                            border-radius: 14px;
                            font-weight: 400;
                            text-align: center;
                            color: #ffffff;
                            line-height: 28px;
                            margin-right: 9px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .tip:last-child {
                            margin-right: 0;
                        }

                        .areaTip {
                            background: #2984ff;
                        }

                        .columnTip {
                            background: #00b9d4;
                        }

                        .industryTip {
                            background: #ff8b00;
                            display: none;
                        }
                    }

                }

                .topicTime {
                    font-weight: 400;
                    text-align: left;
                    color: #999999;
                    line-height: 22px;
                }
            }

            .pageNintionBox {
                padding: 40px 0;
                display: flex;
                justify-content: center;
            }
        }
    }

    .unLoading {
        opacity: 1;
        transition: all 200ms;
    }

    .inLoading {
        opacity: 0;
        transition: all 200ms;
    }
</style>
<!--el 的样式覆盖 -->
<style lang="scss">
    .timePicker {
        width: 119px;
        height: 26px;
        overflow: hidden;
        display: block;
        box-sizing: border-box;
        border: 1px solid #e8e8e8;
        border-radius: 1px;

        input {
            height: 26px !important;
            line-height: 26px !important;
            font-size: 14px !important;
            padding: 0 !important;
            border: none !important;
            text-align: center;
        }

        .el-input__prefix {
            display: none;
        }

        .el-input__suffix {
            display: none;
        }
    }

    .pageNintionBox {
        display: flex;
        align-items: center;
        justify-content: center;

        .pageNumConfirm {
            width: 40px;
            height: 40px;
            opacity: 1;
            background: #ffffff;
            border: 1px solid #f0f2f5;
            border-radius: 50%;
            text-align: center;
            color: #333333;
            line-height: 40px;
            margin-left: 10px;
        }
    }

    .el-pagination.myPager {
        & {
            .btn-prev {
                width: 40px;
                height: 40px;
                opacity: 1;
                background: #ffffff;
                border: 1px solid #f0f2f5;
                border-radius: 50%;
            }

            .btn-next {
                width: 40px;
                height: 40px;
                opacity: 1;
                background: #ffffff;
                border: 1px solid #f0f2f5;
                border-radius: 50%;
            }

            .el-pager {
                .number {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    opacity: 1;
                    background: #ffffff;
                    border-radius: 50%;
                    color: #4486ee;


                }

                .more {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    background-color: transparent;
                    opacity: 1;
                    border-radius: 50%;
                }


            }

            .pageNumTip {
                display: inline-block;
                height: 40px;
                line-height: 40px;
                margin: 0 0px 0 15px;

                span {
                    display: inline;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 40px;
                }

                .pageNumNow {
                    color: #4486ee;
                }
            }

            .el-pagination__jump {
                height: 40px;
                line-height: 40px;

                .el-input {
                    width: 40px;
                    height: 40px;
                    opacity: 1;


                    .el-input__inner {
                        width: 40px;
                        height: 40px;
                        opacity: 1;
                        background: #ffffff;
                        border: 1px solid #f0f2f5;
                        border-radius: 50%;
                    }
                }
            }

            .btn-quickprev {
                display: none;
            }

            .btn-quicknext {
                display: none;
            }



        }

    }

    .el-pagination.myPager.hideLastPage {
        .el-pager {
            .number {
                width: 40px;
                height: 40px;
                line-height: 40px;
                opacity: 1;
                background: #ffffff;
                border-radius: 50%;
                color: #4486ee;

                &:last-child {
                    display: none;
                }

            }

            .more {
                width: 40px;
                height: 40px;
                line-height: 40px;
                background-color: transparent;
                opacity: 1;
                border-radius: 50%;
            }


        }
    }
</style>