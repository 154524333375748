const filterAreaList = [{
        areaName: "华北区",
        children: [{
                areaName: "北京"
            },
            {
                areaName: "河北"
            },
            {
                areaName: "天津"
            },
            {
                areaName: "山西"
            },
            {
                areaName: "内蒙古"
            },
        ]
    },
    {
        areaName: "华南区",
        children: [{
                areaName: "广东"
            },
            {
                areaName: "广西"
            },
            {
                areaName: "海南"
            },
            {
                areaName: "香港"
            },
            {
                areaName: "澳门"
            },
        ]
    },
    {
        areaName: "西北区",
        children: [{
                areaName: "陕西"
            },
            {
                areaName: "甘肃"
            },
            {
                areaName: "青海"
            },
            {
                areaName: "宁夏"
            },
            {
                areaName: "新疆"
            },
        ]
    },
    {
        areaName: "华东区",
        children: [{
                areaName: "上海"
            },
            {
                areaName: "江苏"
            },
            {
                areaName: "浙江"
            },
            {
                areaName: "安徽"
            },
            {
                areaName: "福建"
            },
            {
                areaName: "江西"
            },
            {
                areaName: "山东"
            },
        ]
    },
    {
        areaName: "西南区",
        children: [{
                areaName: "重庆"
            },
            {
                areaName: "贵州"
            },
            {
                areaName: "四川"
            },
            {
                areaName: "云南"
            },
            {
                areaName: "西藏"
            },
        ]
    },
    {
        areaName: "华中区",
        children: [{
                areaName: "河南"
            },
            {
                areaName: "湖北"
            },
            {
                areaName: "湖南"
            },
        ]
    },
    {
        areaName: "东北区",
        children: [{
                areaName: "辽宁"
            },
            {
                areaName: "吉林"
            },
            {
                areaName: "黑龙江"
            },
        ]
    }
]
export default filterAreaList